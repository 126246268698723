<template>
  <van-nav-bar
      title="推荐课程"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      class="bar"
  />

  <van-row justify="space-around">
    <van-col span="6">
      <router-link :to="{path:'/video', query: {id:1}}">
        <img style="width: 100%;" src="https://xinxuemo-images.oss-cn-shanghai.aliyuncs.com/cxyk-img/recommend_null.png" alt="">
      </router-link>

    </van-col>

    <van-col span="12" style="padding: 1.2rem 0 0 0">
      <span>高考作文审题秒破一招鲜</span>
    </van-col>

    <van-col span="2" style="padding: 1.2rem 0 0 0">
      <img @click="show = true" style="width: 100%" src="https://xinxuemo-images.oss-cn-shanghai.aliyuncs.com/cxyk-img/details.png" alt="">
    </van-col>

  </van-row>
  <van-overlay :show="show" @click="show = false" />

</template>

<script>
import { ref } from 'vue';
export default {
  name: "moreCourse",
  setup() {
    const show = ref(false),
    onClickLeft = () => history.back();
    return {
      onClickLeft,
      show
    }

    // return { show };
  },

}
</script>

<style scoped>
.bar{
  background-color: rgb(255,200,8);
  --van-nav-bar-height:7rem;
  --van-nav-bar-title-font-size:1.5rem;
  --van-nav-bar-title-text-color:white;
  margin: 0 0 2rem 0;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
</style>