import {createRouter, createWebHistory} from "vue-router";

// import index from "../views/classIndex"
import course from "../views/courseIndex"
import more from "../views/moreCourse"
import video from "../views/videoIndex"
const routes = [
    {
        path: '/',
        component: course
    },
    {
        path: '/more',
        component: more
    },
    {
        path:'/video',
        component: video
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router