<template>

  <video style="width: 100%" src="http://cdn.bj-jiuqi.com/sv/1236eec2-17de17d07ed/1236eec2-17de17d07ed.mp4" controls="controls" controlslist="nodownload">your browser does not support the video tag</video>
  <p class="recommend"><b>推荐课程</b></p>
</template>

<script>
export default {
  name: "videoIndex"
}
</script>

<style scoped>
.recommend{
  font-size: 2rem;
  margin-left: 1.5rem;
}
</style>