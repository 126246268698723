<template>
  <van-nav-bar
      title="首页"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      class="bar"
  />
  <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
    <van-swipe-item style="text-align: center">
      <img class="banner" src="https://xinxuemo-images.oss-cn-shanghai.aliyuncs.com/cxyk-img/banner.png">
    </van-swipe-item>

  </van-swipe>
  <van-row justify="space-around" style="text-align: center">
    <van-col span="6"><img src="https://xinxuemo-images.oss-cn-shanghai.aliyuncs.com/cxyk-img/chinese.png" alt=""></van-col>
    <van-col span="6"><img src="https://xinxuemo-images.oss-cn-shanghai.aliyuncs.com/cxyk-img/math.png" alt=""></van-col>
    <van-col span="6"><img src="https://xinxuemo-images.oss-cn-shanghai.aliyuncs.com/cxyk-img/english.png" alt=""></van-col>
    <van-col span="6"><img src="https://xinxuemo-images.oss-cn-shanghai.aliyuncs.com/cxyk-img/other.png" alt=""></van-col>
  </van-row>

  <van-row justify="space-between" class="text">

    <van-col span="9" class="recommend"><b>推荐课程</b></van-col>
    <van-col span="9" class="more"><router-link :to="{path:'/more', query: {id:1}}"><span style="">查看更多 ></span></router-link></van-col>
  </van-row>
  <van-row justify="space-between" class="text">
    <van-col span="11"><img class="course_null" src="https://xinxuemo-images.oss-cn-shanghai.aliyuncs.com/cxyk-img/paper.png" alt=""></van-col>
    <van-col span="11"><img class="course_null" src="https://xinxuemo-images.oss-cn-shanghai.aliyuncs.com/cxyk-img/music.png" alt=""></van-col>
  </van-row>

</template>

<script>
export default {
  name: "courseIndex",
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  beforeCreate () {
    document.querySelector('body').setAttribute('style', 'background:white')
  },
}
</script>

<style scoped>
.bar{
  background-color: rgb(255,200,8);
  --van-nav-bar-height:7rem;
  --van-nav-bar-title-font-size:1.5rem;
  /*--van-nav-bar-text-color:white;*/
  --van-nav-bar-title-text-color:white;
}
.banner{
  margin: 1.5rem 0 1.5rem 0;
  width: 95%;
}
.recommend{
  font-size: 1.8rem;
}
.more{
  text-align: right;

}
.text{
  margin: 1.5rem 1rem 0 1rem;
  vertical-align: bottom;
}
.course_null{
  width: 100%;
}
</style>